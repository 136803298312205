@tailwind base;
@tailwind components;
@tailwind utilities;
@headlessui/tailwindcss;

.active{
    @apply border-blue-700
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    z-index: -1;
  }
  
/* Track */
::-webkit-scrollbar-track {
border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #d8d8d8;
opacity: 10px;
border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb:hover{
background: #c9c9c9;
opacity: 10px;
border-radius: 10px;
}